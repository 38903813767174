import {Suspense, useEffect} from "react";
import {Navigate, RouterProvider, createBrowserRouter} from "react-router-dom";
import {ERoute} from "../configs/ERoute";
import {ProtectedRoute} from "./ProtectedRoute";
import {SignInPage} from "../../../pages/SignIn";
import {MainPage} from "../../../pages/Main";
import {ProfilePage} from "pages/Profile";
import {ProfilePersonalData} from "components/ProfilePersonalData";
import {getUserProfileData} from "api/services/Profile/Profile";
import {ProfileRating} from "components/ProfileRating";
import {getLeaderboardData} from "api/services/Leaderboard/Leaderboard";
import {UploadedPage} from "../../../pages/Uploaded";
import {useAuthContext} from "common/hooks/useAuthContext";
import {FavouritePage} from "../../../pages/Favourite";
import {getFavouritePageLoader} from "../../../api/services/FavouritesWindow";
import {getSignInPageLoader} from "../../../api/services/Auth/Auth";
import {LazyAdministrationPage} from "pages/Administration";
import {Wip} from "pages/Service/Wip/Wip";
import {Error} from "pages/Service/Error";
import {LazyAdministrationContent} from "components/Administration/Content";

const Routes = () => {
  const {user, permissions} = useAuthContext();

  useEffect(() => {
    if (
      !permissions?.canManageContent &&
      window.location.pathname.includes(ERoute.ADMINISTRATION)
    )
      window.location.replace(`${window.location.origin}${ERoute.MAIN}`);
  }, [permissions?.canManageContent]);

  const routesForPublic = [
    {
      path: "/about",
      element: <div>About Us</div>,
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: ERoute.MAIN,
      element: <ProtectedRoute />,
      errorElement: <Error />,
      children: [
        {
          path: "",
          element: <MainPage />,
        },
        {
          path: ERoute.CATEGORIES,
          element: <MainPage />,
        },
        {
          path: `${ERoute.PROFILE}/*`,
          element: <ProfilePage />,
          children: [
            {
              index: true,
              element: <Navigate to="personal" replace={true} />,
            },
            {
              path: "personal",
              element: <ProfilePersonalData />,
              loader: getUserProfileData,
            },
            {
              path: "security",
              element: null,
            },
            {
              path: "rating",
              element: <ProfileRating />,
              loader: getLeaderboardData,
            },
            {
              path: "*",
              element: <ProfilePersonalData />,
              loader: getUserProfileData,
            },
          ],
        },
        {
          path: ERoute.UPLOADED,
          element: <UploadedPage />,
        },
        {
          path: ERoute.FAVOURITE,
          element: <FavouritePage />,
          loader: getFavouritePageLoader,
        },
        {
          path: `${ERoute.ADMINISTRATION}/*`,
          element: (
            <Suspense fallback={null}>
              <LazyAdministrationPage />
            </Suspense>
          ),

          children: [
            {
              index: true,
              element: <Navigate to="content" replace={true} />,
            },
            {
              path: "content",
              element: <LazyAdministrationContent />,
            },
            {
              path: "metadata",
              element: <LazyAdministrationContent />,
            },
            {
              path: "users",
              element: <LazyAdministrationContent />,
            },
            {
              path: "analytics",
              element: <LazyAdministrationContent />,
            },
            {
              path: "*",
              element: <LazyAdministrationContent />,
            },
          ],
          errorElement: <Wip />,
        },
        {
          path: ERoute.SERVICE_WIP,
          element: <Wip />,
        },
        {
          path: "*",
          element: <Navigate to={ERoute.MAIN} />,
        },
      ],
    },
  ];

  const routesForNotAuthenticatedOnly = [
    {
      path: ERoute.SIGN_IN,
      element: <SignInPage />,
      loader: getSignInPageLoader,
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(user === null ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;

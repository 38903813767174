import client from "api/client";
import {type AxiosResponse} from "axios";
import {type Paths, type Components} from "../../../types/client";
import {logError} from "common/utils/common";
import {
  reatomAsync,
  withAbort,
  withDataAtom,
  withErrorAtom,
  withStatusesAtom,
} from "@reatom/framework";
import {getCombinedError} from "common/utils/errors";
import {pushToast} from "common/models/toast";

enum EMainWindowUrl {
  TABS = "main/tabs",
}

export async function getTabs(): Promise<AxiosResponse<
  Components.Schemas.MWTab[],
  any
> | null> {
  try {
    return await client.get(EMainWindowUrl.TABS);
  } catch (error) {
    logError(error);
    return null;
  }
}

export const getSubCategoriesAction = reatomAsync(
  async (
    ctx,
    {id}: Paths.ApiMainTabs$IdCategories.Get.PathParameters,
  ): Promise<
    AxiosResponse<Paths.ApiMainTabs$IdCategories.Get.Responses.$200>
  > =>
    await ctx.schedule(
      async () =>
        await client.get(`${EMainWindowUrl.TABS}/${id}/categories`, {
          signal: ctx.controller.signal,
        }),
    ),
  {
    name: "getMainSubCategoriesAction",
    onReject(ctx, error) {
      const combined = getCombinedError(error);

      combined.data &&
        pushToast(ctx, {
          type: "error",
          text: combined.data,
        });
    },
  },
).pipe(
  withDataAtom([], (_ctx, {data}) => {
    return data ?? [];
  }),
  withErrorAtom(),
  withAbort(),
  withStatusesAtom(),
);

import React from "react";
import ReactDOM from "react-dom/client";
import {connectLogger, createCtx} from "@reatom/framework";
import {reatomContext} from "@reatom/npm-react";
import {App} from "./apps/sliderary";
import {isCorporate} from "common/constants/core";
import reportWebVitals from "./reportWebVitals";

window.SL_VERSION = SL_VERSION;

if (process.env.NODE_ENV === "development") {
  window.ARGO_ENV = ARGO_ENV;
  window.FEATURE_FLAGS = FEATURE_FLAGS;
}

const element = document.getElementById("root") as Element;

const root = ReactDOM.createRoot(element);

element.className = `${isCorporate ? "light-corporate" : "educational"}-theme`;

const ctx = createCtx();

root.render(
  <React.StrictMode>
    <reatomContext.Provider value={ctx}>
      <App />
    </reatomContext.Provider>
  </React.StrictMode>,
);

if (process.env.NODE_ENV === "development" && ARGO_ENV.debug) {
  connectLogger(ctx);
  reportWebVitals(console.log);
}

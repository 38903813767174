import client from "api/client";
import {objectToUrlString} from "common/utils/http";
import {type Components, type Paths} from "types/client";
import {type GenericAbortSignal} from "axios";
import {type AsyncAxiosResponse} from "types/common";

export enum EExportUrl {
  PANEL_DATA = "/export/slides",
  EXPORT = "/export",
  EXPORT_CATEGORY = "/export/category",
  EXPORT_PRESENTATION = "presentation",
}

export async function getExportPanelData(
  params: Paths.ApiExportSlides.Get.QueryParameters,
  signal?: GenericAbortSignal,
): AsyncAxiosResponse<
  Components.Schemas.ExportSlidePage,
  Record<string, unknown>
> {
  return await client.get(
    `${EExportUrl.PANEL_DATA}?${objectToUrlString(params)}`,
    {signal},
  );
}

/**
 * Начать экспорт презентации. Метод вернет id, с этим id надо долбится в бэк для ожидания возвращения ссылки на презентацию (getDownloadPresentationLink).
 */
export async function startPresentationExport(
  params: Paths.ApiExport.Post.RequestBody,
  signal?: GenericAbortSignal,
): AsyncAxiosResponse<number, Record<string, unknown>> {
  return await client.post(`${EExportUrl.EXPORT}`, params, {signal});
}

/**
 * Метод получения ссылки на загрузку презентации. Работает по методу long-polling.
 */
export async function getDownloadPresentationLink(
  params: Paths.ApiExport$Id.Get.PathParameters,
  signal?: GenericAbortSignal,
): AsyncAxiosResponse<string, Record<string, unknown>> {
  return await client.get(`${EExportUrl.EXPORT}/${params.id}`, {signal});
}

export async function getExportCategory(
  params: Paths.ApiExportCategory$Id.Get.PathParameters,
  signal?: GenericAbortSignal,
): AsyncAxiosResponse<Array<{id: number; slideId: number}>, any> {
  return await client.get(`${EExportUrl.EXPORT_CATEGORY}/${params.id}`, {
    signal,
  });
}

export async function getExportPresentation(
  params: Paths.ApiExportCategory$CidPresentation$Pid.Get.PathParameters,
  signal?: GenericAbortSignal,
): AsyncAxiosResponse<Array<{id: number; slideId: number}>, any> {
  return await client.get(
    `${EExportUrl.EXPORT_CATEGORY}/${params.cid}/${EExportUrl.EXPORT_PRESENTATION}/${params.pid}`,
    {
      signal,
    },
  );
}

import {isRouteErrorResponse} from "react-router-dom";
import {isAxiosError, isCancel, type AxiosError} from "axios";
import {type ICombinedError} from "types/common";
import {UNKNOWN_ERROR_MESSAGE} from "common/constants/constants";

export function getCombinedError(error: unknown) {
  let result: ICombinedError = {
    status: null,
    statusText: null,
    data: null,
    error: null,
  };

  if (isRouteErrorResponse(error)) {
    const {internal, data, ...rest} = error;
    result = {...rest, data: String(data)};
  } else if (isAxiosError(error)) {
    const {response} = error as AxiosError;
    let status, title;
    if (response?.data) {
      status = (response.data as {status: number; title: string}).status;
      title = (response.data as {status: number; title: string}).title;
    } else {
      status = error.status;
      title = UNKNOWN_ERROR_MESSAGE;
    }

    result = {
      ...result,
      status: status ?? null,
      statusText: isCancel(error) ? "Запрос отменен" : null,
      data: title,
    };
  } else if (error instanceof Error) {
    const {message} = error;
    result = {...result, data: message};
  } else {
    result = {...result, data: String(error)};
  }

  return result;
}

import {
  createContext,
  useEffect,
  useState,
  type ReactNode,
  type SetStateAction,
  type Dispatch,
} from "react";
import {getFromStorage, setToStorage} from "common/utils/localStorage";
import {logError} from "common/utils/common";
import {
  getExportCategory,
  getExportPresentation,
} from "../api/services/Export/Export";
import {useAuthContext} from "common/hooks/useAuthContext";
import {ELocalStorageKeys} from "common/constants/enums";

interface IStorageProvider {
  children: ReactNode;
}

interface IStorageContext {
  selectable: number[] | null;
  setSelectable: Dispatch<SetStateAction<number[]>>;
  slideIdToIdMap: Record<number, number> | null;
  setSlideIdToIdMap: Dispatch<SetStateAction<Record<number, number>>>;
  onSelectableChange: (id: number, slideId: number, cId: number) => void;
  cleanAll: () => void;
  getSlideIdFromId: (id: number) => string | undefined;
  categoryIdToIdMap: Record<string, number[]> | null;
  presentationIdToIdMap: Record<string, number[]> | null;
  getCategoryIdFromId: (id: number) => string | undefined;
  removeCategory: (categoryId: number) => void;
  addCategory: (categoryId: number) => void;
  addPresentation: (categoryId: number, presentationId: number) => void;
  removePresentation: (presentationId: number) => void;
}

interface IAddStates {
  selectable: number[];
  slideIdToIdMap: Record<number, number>;
  categoryIdToIdMap: Record<number, number[]>;
  presentationIdToIdMap: Record<number, number[]>;
  categoryIdToPresentationIdMap: Record<number, number[]>;
}

const StorageContext = createContext<IStorageContext | null>(null);
export const StorageContextProvider = ({children}: IStorageProvider) => {
  const {user, permissions} = useAuthContext();
  const [selectable, setSelectable] = useState<number[]>(() =>
    getFromStorage(ELocalStorageKeys.SELECTABLE_ITEMS, []),
  );
  const [slideIdToIdMap, setSlideIdToIdMap] = useState<Record<number, number>>(
    () => getFromStorage(ELocalStorageKeys.SLIDE_ID_TO_ID_MAP, {}),
  );
  const [categoryIdToIdMap, setCategoryIdToIdMap] = useState<
    Record<number, number[]>
  >(() => getFromStorage(ELocalStorageKeys.CATEGORY_ID_TO_ID_MAP, {}));
  const [presentationIdToIdMap, setPresentationIdToIdMap] = useState<
    Record<number, number[]>
  >(() => getFromStorage(ELocalStorageKeys.PRESENTATION_ID_TO_ID_MAP, {}));

  useEffect(() => {
    user && setToStorage(ELocalStorageKeys.SELECTABLE_ITEMS, selectable);
  }, [selectable, user]);
  useEffect(() => {
    user && setToStorage(ELocalStorageKeys.SLIDE_ID_TO_ID_MAP, slideIdToIdMap);
  }, [slideIdToIdMap, user]);
  useEffect(() => {
    if (permissions && !permissions.canExport && selectable.length) {
      cleanAll();
    }
  }, [permissions]);
  useEffect(() => {
    user &&
      setToStorage(ELocalStorageKeys.CATEGORY_ID_TO_ID_MAP, categoryIdToIdMap);
  }, [categoryIdToIdMap, user]);
  useEffect(() => {
    user &&
      setToStorage(
        ELocalStorageKeys.PRESENTATION_ID_TO_ID_MAP,
        presentationIdToIdMap,
      );
  }, [presentationIdToIdMap, user]);

  const getSlideIdFromId = (id: number) =>
    slideIdToIdMap &&
    Object.keys(slideIdToIdMap).find(key => slideIdToIdMap[+key] === id);

  const removeKey = (key: string, {[key]: _, ...rest}) => rest;

  const removeKeys = (removedKeys: string[], obj: any) => {
    let newState = {...obj};
    removedKeys.forEach(removedKey => {
      newState = removeKey(removedKey, newState);
    });
    return newState;
  };

  const onSelectableChange = (
    id: number,
    slideId: number,
    categoryId: number,
    presentationId?: number,
  ) => {
    if (!id || !slideId || !categoryId) return;

    const originalId = slideIdToIdMap[slideId];
    const removeKey = (key: string, {[key]: _, ...rest}) => rest;

    if (originalId) {
      setSelectable(prev =>
        prev.filter(selectedId => selectedId !== originalId),
      );
      setSlideIdToIdMap(prev => removeKey(String(slideId), prev));

      setCategoryIdToIdMap(prev => ({
        ...prev,
        [categoryId]: [
          ...(prev[categoryId]?.filter(selectedId => selectedId !== id) ?? []),
        ],
      }));

      if (presentationId) {
        setPresentationIdToIdMap(prev => ({
          ...prev,
          [presentationId]: [
            ...(prev[presentationId]?.filter(selectedId => selectedId !== id) ??
              []),
          ],
        }));
      }
    } else {
      setSelectable(prev => [...prev, id]);
      setSlideIdToIdMap(prev => ({...prev, [slideId]: id}));

      setCategoryIdToIdMap(prev => ({
        ...prev,
        [categoryId]: [...(prev[categoryId] ?? []), id],
      }));

      if (presentationId) {
        setPresentationIdToIdMap(prev => ({
          ...prev,
          [presentationId]: [...(prev[presentationId] ?? []), id],
        }));
      }
    }
  };

  const cleanAll = () => {
    setSelectable([]);
    setSlideIdToIdMap({});
    setCategoryIdToIdMap({});
    setPresentationIdToIdMap({});
  };

  const getCategoryIdFromId = (id: number) =>
    categoryIdToIdMap &&
    Object.keys(categoryIdToIdMap).find(item =>
      categoryIdToIdMap[+item]?.includes(id),
    );

  const removeCategory = (categoryId: number) => {
    const needRemoveIds = categoryIdToIdMap[categoryId];

    const needRemoveSlideIds = Object.keys(slideIdToIdMap).filter(key =>
      needRemoveIds?.includes(slideIdToIdMap[+key] as number),
    );

    setCategoryIdToIdMap(prev => removeKey(categoryId.toString(), prev));
    setSlideIdToIdMap(prev => removeKeys(needRemoveSlideIds, prev));
    setSelectable(prev =>
      prev.filter(selectedId => !needRemoveIds?.includes(selectedId)),
    );
  };

  const removePresentation = (presentationId: number) => {
    const needRemoveIds = presentationIdToIdMap[presentationId] ?? [];

    const affectedCategories: number[] = [];

    Object.keys(categoryIdToIdMap).forEach(categoryIdStr => {
      const categoryId = Number(categoryIdStr);
      const categorySlides = categoryIdToIdMap[categoryId] ?? [];

      const hasSlideFromPresentation = categorySlides.some(slideId =>
        needRemoveIds.includes(slideId),
      );

      if (hasSlideFromPresentation) {
        affectedCategories.push(categoryId);
      }
    });

    const needRemoveSlideIds = Object.keys(slideIdToIdMap).filter(key =>
      needRemoveIds.includes(slideIdToIdMap[+key] as number),
    );

    setPresentationIdToIdMap(prev =>
      removeKey(presentationId.toString(), prev),
    );

    setSlideIdToIdMap(prev => removeKeys(needRemoveSlideIds, prev));

    setSelectable(prev =>
      prev.filter(selectedId => !needRemoveIds.includes(selectedId)),
    );

    setCategoryIdToIdMap(prev => {
      let updated = {...prev};

      affectedCategories.forEach(categoryId => {
        const categorySlides = updated[categoryId] ?? [];
        updated[categoryId] = categorySlides.filter(
          slideId => !needRemoveIds.includes(slideId),
        );

        if (updated[categoryId].length === 0) {
          const categoryIdStr = categoryId.toString();
          updated = removeKey(categoryIdStr, updated);
        }
      });

      return updated;
    });
  };

  const addCategory = (categoryId: number) => {
    if (categoryId) {
      getExportCategory({id: categoryId})
        .then(response => {
          if (response.data.length) {
            const addStates: IAddStates = {
              selectable: [],
              slideIdToIdMap: {},
              categoryIdToIdMap: {},
              presentationIdToIdMap: {},
              categoryIdToPresentationIdMap: {},
            };

            response.data.forEach(item => {
              if (!selectable.includes(item.id)) {
                addStates.selectable.push(item.id);
              }

              if (!slideIdToIdMap[item.slideId]) {
                addStates.slideIdToIdMap[item.slideId] = item.id;
              }

              if (!addStates.categoryIdToIdMap[categoryId]) {
                addStates.categoryIdToIdMap[categoryId] = [];
              }

              if (!addStates.categoryIdToIdMap[categoryId].includes(item.id)) {
                addStates.categoryIdToIdMap[categoryId].push(item.id);
              }
            });

            updateStates(addStates);
          }
        })
        .catch(error => {
          logError(error);
        });
    }
  };

  const addPresentation = (categoryId: number, presentationId: number) => {
    if (categoryId && presentationId) {
      getExportPresentation({cid: categoryId, pid: presentationId})
        .then(response => {
          if (response.data.length) {
            const addStates: IAddStates = {
              selectable: [],
              slideIdToIdMap: {},
              categoryIdToIdMap: {},
              presentationIdToIdMap: {},
              categoryIdToPresentationIdMap: {},
            };

            response.data.forEach(item => {
              if (!selectable.includes(item.id)) {
                addStates.selectable.push(item.id);
              }

              if (!slideIdToIdMap[item.slideId]) {
                addStates.slideIdToIdMap[item.slideId] = item.id;
              }

              if (!addStates.categoryIdToIdMap[categoryId]) {
                addStates.categoryIdToIdMap[categoryId] = [];
              }

              if (!addStates.categoryIdToIdMap[categoryId].includes(item.id)) {
                addStates.categoryIdToIdMap[categoryId].push(item.id);
              }

              if (!addStates.presentationIdToIdMap[presentationId]) {
                addStates.presentationIdToIdMap[presentationId] = [];
              }

              if (
                !addStates.presentationIdToIdMap[presentationId].includes(
                  item.id,
                )
              ) {
                addStates.presentationIdToIdMap[presentationId].push(item.id);
              }
            });

            updateStates(addStates);
          }
        })
        .catch(error => {
          logError(error);
        });
    }
  };

  const updateStates = (addStates: IAddStates) => {
    setSelectable(prev => [
      ...prev,
      ...addStates.selectable.filter(item => !prev.includes(item)),
    ]);

    setSlideIdToIdMap(prev => ({...prev, ...addStates.slideIdToIdMap}));

    setCategoryIdToIdMap(prev => {
      const result = {...prev};

      Object.keys(addStates.categoryIdToIdMap).forEach(categoryId => {
        const categoryIdNum = Number(categoryId);
        const categoryItems = addStates.categoryIdToIdMap[categoryIdNum];

        if (!categoryItems) return;

        if (!result[categoryIdNum]) {
          result[categoryIdNum] = [];
        }

        const currentItems = result[categoryIdNum] || [];
        const newItems = categoryItems.filter(
          item => !currentItems.includes(item),
        );

        result[categoryIdNum] = [...currentItems, ...newItems];
      });

      return result;
    });

    setPresentationIdToIdMap(prev => {
      const result = {...prev};

      Object.keys(addStates.presentationIdToIdMap).forEach(presentationId => {
        const presentationIdNum = Number(presentationId);
        const presentationItems =
          addStates.presentationIdToIdMap[presentationIdNum];

        if (!presentationItems) return;

        if (!result[presentationIdNum]) {
          result[presentationIdNum] = [];
        }

        const currentItems = result[presentationIdNum] || [];
        const newItems = presentationItems.filter(
          item => !currentItems.includes(item),
        );

        result[presentationIdNum] = [...currentItems, ...newItems];
      });

      return result;
    });
  };

  return (
    <StorageContext.Provider
      value={{
        slideIdToIdMap,
        selectable,
        setSlideIdToIdMap,
        setSelectable,
        onSelectableChange,
        cleanAll,
        getSlideIdFromId,
        categoryIdToIdMap,
        presentationIdToIdMap,
        getCategoryIdFromId,
        addCategory,
        removeCategory,
        addPresentation,
        removePresentation,
      }}>
      {children}
    </StorageContext.Provider>
  );
};

export default StorageContext;

import {action, atom} from "@reatom/framework";
import {type IToastMessage} from "../types/toast";
import {TIMEOUT_TO_HIDE_MS} from "../constants/toast";

export const isAnimateAtom = atom(false, "isAnimate");
export const isToastShownAtom = atom(false, "isToastShown");
export const toastsAtom = atom<IToastMessage[]>([], "toasts");
export const hideTimeoutIdAtom = atom<NodeJS.Timeout | null>(
  null,
  "toastHideTimeoutId",
);
export const animateTimeoutIdAtom = atom<NodeJS.Timeout | null>(
  null,
  "toastAnimateTimeoutId",
);
export const pushToast = action((ctx, newAction: IToastMessage) => {
  toastsAtom(ctx, () => [newAction]);
}, "pushToast");

const clearAnimateTimeoutId = action(ctx => {
  const animateTimeoutId = ctx.get(animateTimeoutIdAtom);
  animateTimeoutId && clearTimeout(animateTimeoutId);
});
const clearHideTimeoutId = action(ctx => {
  const timeoutId = ctx.get(hideTimeoutIdAtom);
  timeoutId && clearTimeout(timeoutId);
});
pushToast.onCall((ctx, _payload, params) => {
  if (params.length) {
    clearAnimateTimeoutId(ctx);
    clearHideTimeoutId(ctx);
    isToastShownAtom(ctx, true);
    const newTimeoutId = setTimeout(
      () => isToastShownAtom(ctx, false),
      TIMEOUT_TO_HIDE_MS,
    );
    hideTimeoutIdAtom(ctx, newTimeoutId);
  }
});

isToastShownAtom.onChange((ctx, isShown) => {
  if (isShown) {
    setTimeout(() => isAnimateAtom(ctx, true), 0);
  } else if (ctx.get(isAnimateAtom)) {
    clearAnimateTimeoutId(ctx);
    const animateTimeoutId = setTimeout(() => isAnimateAtom(ctx, false), 2000);
    animateTimeoutIdAtom(ctx, animateTimeoutId);
  }
});

isAnimateAtom.onChange((ctx, isAnimate) => {
  if (!isAnimate) {
    clearAnimateTimeoutId(ctx);
    clearHideTimeoutId(ctx);
    toastsAtom(ctx, []);
  }
});
